import { Navigate, useRoutes, Route } from "react-router-dom";
import Land from "./pages/Land";
import Hello from "./pages/Hello";
import Nav from "./pages/Nav";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Land />,
    },
    {
      path: "/navs",
      element: <Nav />,
    },
  ]);

  return routes;
}
