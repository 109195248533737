import React from "react";
import "./land.css";

const Landing = () => {
  return (
    <div className="dashbord-shadow d-flex justify-content-center align-items-center hun">
      <iframe
        title="Wistia Video"
        src="https://fast.wistia.net/embed/iframe/6ksbr4cvky"
        allowFullScreen
        frameBorder="0"
        scrolling="no"
        style={{
          height: "85%",
          position: "relative",
          width: "85%",
        }}
      ></iframe>
    </div>
  );
};

export default Landing;
