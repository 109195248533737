// ParentTable.js
import React, { useState } from "react";
import Nav from "./Nav";
import vivid from "./vivid.jpg";
import trust from "./pil2.png";
import trust2 from "./hull2.png";
import trust3 from "./oneTree.jpeg";
import trust4 from "./pnghut.png";
import dash1 from "./19.png";
import dash2 from "./20.png";
import ten from "./10.png";
import tenten from "./case1.png";
import case2 from "./case2.png";
import case3 from "./case3.png";
import case4 from "./case4.png";
import aa from "./115.png";
import bb from "./116.png";
import cc from "./117.png";
import dd from "./118.png";
import ee from "./119.png";
import ff from "./mark.png";
import gant from "./gg.png";
import take from "./12.png";
import dash3 from "./21.png";
import meta from "./meta.png";
import ban from "./page.png";
import newImage from "./new5.png";

import videoSource from "./adv.mp4";
import "./land.css";
import Landing from "./Landing";
import Faq from "./Faq";
const Land = () => {
  const vividImage = `url(${vivid})`; // Convert the image path to a URL string
  const tenImage = `url(${ten})`; // Convert the image path to a URL string
  const takeImage = `url(${take})`; // Convert the image path to a URL string
  const banImage = `url(${ban})`; // Convert the image path to a URL string

  return (
    <>
      <div>
        <body style={{ backgroundColor: "black" }}>
          <main>
            <section
              class="hero-area hero-space-1 bg-top-left pt-190 pb-150"
              data-bg-color="#eeeff4"
              style={{
                backgroundImage: vividImage,
                // Add other styles as needed
              }}
            >
              <img
                src={newImage}
                style={{
                  display: "block", // Ensures that the margin: auto works
                  height: "100px",
                  margin: "auto", // Center horizontally
                  marginBottom: "40px",
                }}
              />

              <div class="container">
                <div
                  className="hen"
                  style={{
                    backgroundImage: banImage, // Update to use url() for background image
                  }}
                >
                  <h6
                    class="sasup-s-title wow fadeInUp band"
                    data-wow-delay=".2s"
                  >
                    E-Commerce Brands Above $25,000/m
                  </h6>
                </div>

                <div class="hero-inner p-rel">
                  <div class="hero-shapes">
                    <div class="shape1 p-abs">
                      <img
                        src="assets/img/shape/shape_01.webp"
                        alt="image not found"
                      />
                    </div>
                    <div class="shape2 p-abs">
                      <img
                        src="assets/img/shape/shape_02.webp"
                        alt="image not found"
                      />
                    </div>
                    <div class="shape3 p-abs d-none d-xl-block">
                      <img
                        src="assets/img/shape/shape_03.webp"
                        alt="image not found"
                      />
                    </div>
                    <div class="shape4 p-abs">
                      <img
                        src="assets/img/shape/shape_04.webp"
                        alt="image not found"
                      />
                    </div>
                  </div>
                  <div class="sasup-hero-content text-center">
                    <span
                      class="sasup-hero-content-sub wow fadeInUp"
                      data-wow-delay="0s"
                      style={{ marginTop: "30px", marginBottom: "30px" }}
                    >
                      New Training Reveals...
                    </span>
                    <h6
                      class=" wow fadeInUp scr"
                      data-wow-delay=".3s"
                      style={{
                        marginBottom: "20px",
                        textTransform: "uppercase",
                      }}
                    >
                      Skyrocket Your Sales With Our{" "}
                      <span style={{ color: "#ffaa33" }}>Psychology</span>{" "}
                      <br></br> &{" "}
                      <span style={{ color: "#ffaa33" }}> Data-Driven</span>{" "}
                      Creative Frameworks! Backed <br></br>By Over $6 Million In
                      Yearly Ad Spend.
                    </h6>

                    <span
                      class="sasup-hero-content-sub wow fadeInUp"
                      data-wow-delay="0s"
                      style={{ marginTop: "30px", marginBottom: "30px" }}
                    >
                      And watch your ads print 3-8 ROAS with ease...
                    </span>

                    <Landing />

                    <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                      <a
                        href="https://calendly.com/adzenon-xta/free-30-minute-growth-map-scaling-session"
                        style={{
                          backgroundColor: "white",
                          padding: "20px",
                          paddingLeft: "100px",
                          paddingRight: "100px",

                          borderRadius: "30px",
                        }}
                      >
                        <span>
                          <b>Apply Now</b>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div
              class="subscription-area pt-10 pb-75 bg-default text-center"
              style={{
                backgroundColor: "white",

                height: "30px",
              }}
            >
              <img
                src={trust}
                style={{
                  height: "70px",
                  marginTop: "0",
                }}
              />
              <img
                src={meta}
                style={{
                  height: "70px",
                  marginTop: "0",
                }}
              />
            </div>
            <div
              class="subscription-area pt-10 pb-75 bg-default text-center"
              style={{
                height: "120px",
              }}
            >
              <span style={{ color: "white", marginRight: "20px" }}>
                AS SEEN ON
              </span>

              <img
                src={trust4}
                style={{
                  height: "100px",
                  marginTop: "0",
                }}
              />
            </div>
            <section>
              <div style={{ backgroundColor: "white" }}>
                <div
                  class="container"
                  style={{
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    borderRadius: "20px",
                  }}
                >
                  <section
                    class="service-area"
                    style={{ marginBottom: "30px" }}
                  >
                    <div class="row align-items-stretch">
                      <div class="col-lg-7">
                        <div class="service-cardss">
                          <div class="service-card-contents">
                            <h3
                              class="service-titles"
                              style={{
                                color: "black",
                                fontWeight: "600",
                                marginTop: "50px",
                              }}
                            >
                              TIRED OF{" "}
                              <span style={{ color: "#ffaa33" }}>STAGNANT</span>{" "}
                              SALES AND{" "}
                              <span style={{ color: "#ffaa33" }}>LUKEWARM</span>{" "}
                              GROWTH
                            </h3>
                            <p
                              class="service-descriptions"
                              style={{ color: "black" }}
                            >
                              Adzenon is your escape pod from e-commerce
                              frustration.
                            </p>

                            <ul>
                              <li
                                style={{
                                  color: "black",
                                  listStyle: "none",
                                  position: "relative",
                                  marginBottom: "12px",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "24px",
                                    color: "#000",
                                    lineHeight: 0, // Set line height to 0 to remove extra space
                                  }}
                                >
                                  ▶︎
                                </span>
                                <span>
                                  <span style={{ color: "#ffaa33" }}>
                                    <b> Grow Fast.</b>{" "}
                                  </span>{" "}
                                  Our CAF System crafts magnetic content,
                                  attracts ideal customers, and guides them to
                                  buy.
                                </span>
                              </li>
                              <li
                                style={{
                                  color: "black",
                                  listStyle: "none",
                                  marginBottom: "12px",
                                  position: "relative",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "24px",
                                    color: "#000",
                                    lineHeight: 0, // Set line height to 0 to remove extra space
                                  }}
                                >
                                  ▶︎
                                </span>
                                <span>
                                  <span style={{ color: "#ffaa33" }}>
                                    {" "}
                                    <b> Target Like a Sniper.</b>{" "}
                                  </span>{" "}
                                  No wasted ads. We reach the right people, at
                                  the right time.
                                </span>
                              </li>
                              <li
                                style={{
                                  color: "black",
                                  listStyle: "none",
                                  marginBottom: "12px",
                                  position: "relative",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "24px",
                                    color: "#000",
                                    lineHeight: 0, // Set line height to 0 to remove extra space
                                  }}
                                >
                                  ▶︎
                                </span>
                                <span>
                                  <span style={{ color: "#ffaa33" }}>
                                    {" "}
                                    <b> Global Content Creators.</b>
                                  </span>{" "}
                                  50+ storytellers showcase your brand
                                  authentically.
                                </span>
                              </li>
                              <li
                                style={{
                                  color: "black",
                                  listStyle: "none",
                                  marginBottom: "12px",
                                  position: "relative",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "24px",
                                    color: "#000",
                                    lineHeight: 0, // Set line height to 0 to remove extra space
                                  }}
                                >
                                  ▶︎
                                </span>
                                <span>
                                  <span style={{ color: "#ffaa33" }}>
                                    {" "}
                                    <b> Long-Term Partner.</b>
                                  </span>{" "}
                                  We optimize, adapt, and celebrate with you
                                  every step.
                                </span>
                              </li>
                              <li
                                style={{
                                  color: "black",
                                  listStyle: "none",
                                  position: "relative",
                                  marginBottom: "12px",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "24px",
                                    color: "#000",
                                    lineHeight: 0, // Set line height to 0 to remove extra space
                                  }}
                                >
                                  ▶︎
                                </span>
                                <span>
                                  <span style={{ color: "#ffaa33" }}>
                                    {" "}
                                    <b> Data-Driven Results.</b>
                                  </span>{" "}
                                  We analyze, plan, and get real results.
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-5" style={{ marginTop: "60px" }}>
                        <div class="service-cards">
                          <img
                            src="assets/img/service/2.png"
                            class="service-image"
                            style={{ height: "400px" }}
                            alt="Image not found"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bts">
                      <a
                        href="https://calendly.com/adzenon-xta/free-30-minute-growth-map-scaling-session"
                        className="btns"
                      >
                        <b>Apply Now</b>
                      </a>
                    </div>
                  </section>
                </div>
              </div>
              <div class="container" style={{ marginTop: "50px" }}>
                <div
                  class="row justify-content-center"
                  style={{ marginTop: "60px" }}
                >
                  <div class="col-xl-10">
                    <div>
                      <div class="sasup-policy__item mb-35">
                        <ul style={{ listStyle: "none" }}>
                          <li style={{ listStyle: "none", color: "white" }}>
                            Dear E-com Brand Builder,
                          </li>
                          <br></br>
                          <li style={{ color: "white" }}>
                            Remember that first sale?
                          </li>
                          <br></br>
                          <li style={{ color: "white" }}>
                            The <span style={{ color: "#ffaa33" }}>thrill</span>
                            , the{" "}
                            <span style={{ color: "#ffaa33" }}>Validation</span>
                            , the{" "}
                            <span style={{ color: "#ffaa33" }}>
                              endless possibilities
                            </span>
                            ?
                          </li>
                          <br></br>
                          <li style={{ color: "white" }}>
                            Now, are you staring at{" "}
                            <b>flatlining sales, ads that fizzle,</b> and
                            <b> customers disappearing</b> faster than a
                            magician's dove?
                          </li>
                          <br></br>
                          <li style={{ color: "white" }}>
                            You're not alone. Jargon-filled pitches, empty
                            promises, and "secret formula" agencies often leave
                            you frustrated and empty-handed.
                          </li>
                          <br></br>
                          <li style={{ color: "white" }}>
                            The truth? Sustainable e-commerce growth isn't
                            magic. It's about{" "}
                            <span style={{ color: "#6ebd45" }}>
                              understanding your brand deeply
                              <span style={{ color: "white" }}>,</span>{" "}
                              captivating
                            </span>{" "}
                            your
                            <span style={{ color: "#6ebd45" }}>
                              {" "}
                              ideal customers,
                            </span>{" "}
                            and{" "}
                            <span style={{ color: "#6ebd45" }}>
                              guiding
                            </span>{" "}
                            them seamlessly to checkout.
                          </li>
                        </ul>
                      </div>
                      <div class="sasup-policy__item mb-35">
                        <p style={{ color: "white" }}>Imagine:</p>
                        <ul>
                          <li
                            style={{
                              color: "white",
                              listStyle: "none",
                              position: "relative",
                              display: "flex", // Add display flex to align items horizontally
                              alignItems: "center", // Align items vertically to center
                            }}
                          >
                            <span
                              style={{
                                marginRight: "10px",
                                fontSize: "64px",
                                color: "#ffaa33",
                                lineHeight: 0, // Set line height to 0 to remove extra space
                              }}
                            >
                              •
                            </span>
                            <span>
                              Content that <b>stops scrollers</b> dead in their
                              tracks.
                            </span>
                          </li>
                          <li
                            style={{
                              color: "white",
                              listStyle: "none",
                              position: "relative",
                              display: "flex", // Add display flex to align items horizontally
                              alignItems: "center", // Align items vertically to center
                            }}
                          >
                            <span
                              style={{
                                marginRight: "10px",
                                fontSize: "64px",
                                color: "#ffaa33",
                                lineHeight: 0, // Set line height to 0 to remove extra space
                              }}
                            >
                              •
                            </span>
                            <span>
                              <b> Laser-targeted ads </b> that reach your
                              perfect audience.
                            </span>
                          </li>
                          <li
                            style={{
                              color: "white",
                              listStyle: "none",
                              position: "relative",
                              display: "flex", // Add display flex to align items horizontally
                              alignItems: "center", // Align items vertically to center
                            }}
                          >
                            <span
                              style={{
                                marginRight: "10px",
                                fontSize: "64px",
                                color: "#ffaa33",
                                lineHeight: 0, // Set line height to 0 to remove extra space
                              }}
                            >
                              •
                            </span>
                            <span>
                              A <b>seamless</b> customer journey designed to{" "}
                              <b>convert</b> effortlessly.
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div class="sasup-policy__item mb-35">
                        <p style={{ color: "white" }}>
                          Now, imagine plugging in a system that delivers all of
                          this, powered by:
                        </p>
                        <ul>
                          <li
                            style={{
                              color: "white",
                              listStyle: "none",
                              position: "relative",
                              display: "flex", // Add display flex to align items horizontally
                              alignItems: "center", // Align items vertically to center
                            }}
                          >
                            <span
                              style={{
                                marginRight: "10px",
                                fontSize: "24px",
                                color: "#ffaa33",
                                lineHeight: 0, // Set line height to 0 to remove extra space
                              }}
                            >
                              ▶︎
                            </span>
                            <span>
                              <b> Deep consumer psychology</b> insights to
                              understand what makes your customers tick.
                            </span>
                          </li>
                          <li
                            style={{
                              color: "white",
                              listStyle: "none",
                              position: "relative",
                              display: "flex", // Add display flex to align items horizontally
                              alignItems: "center", // Align items vertically to center
                            }}
                          >
                            <span
                              style={{
                                marginRight: "10px",
                                fontSize: "24px",
                                color: "#ffaa33",
                                lineHeight: 0, // Set line height to 0 to remove extra space
                              }}
                            >
                              ▶︎
                            </span>
                            <span>
                              <b> Data-driven targeting</b> that adapts to
                              changing algorithms and customer behavior.
                            </span>
                          </li>
                          <li
                            style={{
                              color: "white",
                              listStyle: "none",
                              position: "relative",
                              display: "flex", // Add display flex to align items horizontally
                              alignItems: "center", // Align items vertically to center
                            }}
                          >
                            <span
                              style={{
                                marginRight: "10px",
                                fontSize: "24px",
                                color: "#ffaa33",
                                lineHeight: 0, // Set line height to 0 to remove extra space
                              }}
                            >
                              ▶︎
                            </span>
                            <span>
                              Proven methods for crafting{" "}
                              <b>magnetic content</b> and building{" "}
                              <b>seamless funnels.</b>
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div class="sasup-policy__item mb-35">
                        <ul>
                          <li style={{ color: "#6ebd45" }}>
                            *Cue angels singing*
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*}  <h3
              class="sasup-s-title wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                textAlign: "center",
                color: "white",
                textShadow:
                  "0 0 10px #00FF00, 0 0 20px #00FF00, 0 0 30px #00FF00, 0 0 40px #00FF00, 0 0 50px #00FF00, 0 0 60px #00FF00, 0 0 70px #00FF00, 0 0 80px #00FF00, 0 0 90px #00FF00, 0 0 100px #00FF00",
              }}
            >
              THE CAF SYSTEM
            </h3>*/}
            <h3
              class="sasup-s-title wow fadeInUp siz"
              data-wow-delay=".2s"
              style={{
                textAlign: "center",
                color: "#6ebd45",
              }}
            >
              THE CAF SYSTEM
            </h3>
            <p
              // class="sasup-s-title wow fadeInUp"
              data-wow-delay=".2s"
              className="create"
              style={{ marginBottom: "50px" }}
            >
              Creatives that <span style={{ color: "#6ebd45" }}>Convert</span>,
              Audiences that <span style={{ color: "#6ebd45" }}>Act</span>,
              Funnels that <span style={{ color: "#ffaa33" }}>Flow.</span>
            </p>

            <div class="container" style={{ padding: "0 15px" }}>
              <section class="service-area" style={{ marginBottom: "30px" }}>
                <div class="row align-items-stretch">
                  <div class="col-lg-6 " style={{ border: " 2px solid white" }}>
                    <div class="service-card">
                      <div class="service-card-content">
                        <h3 class="service-title">
                          Creatives that{" "}
                          <span style={{ color: "#ffaa33" }}>Convert</span>
                        </h3>
                        <p class="service-description">
                          Ditch the stock photos and generic blurbs. We weave
                          magic with magnetic user-generated content, stories
                          that stop scrollers dead in their tracks and pull them
                          into your brand universe.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 love">
                    <div class="service-card" style={{}}>
                      <img
                        src="assets/img/service/image.jpg"
                        class="service-imagemjgj"
                        style={{
                          border: "2px solid white",
                          width: "100%",
                          height: "330px",
                        }}
                        alt="Image not found"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section class="service-area" style={{ marginBottom: "30px" }}>
                <div class="row align-items-stretch">
                  <div class="col-lg-6 " style={{ border: " 2px solid white" }}>
                    <div class="service-card">
                      <div class="service-card-content">
                        <h3 class="service-title">
                          Audiences that{" "}
                          <span style={{ color: "#ffaa33" }}>Act</span>
                        </h3>
                        <p class="service-description">
                          We use laser-sharp AI-powered targeting to attract
                          your ideal customers, the ones who resonate with your
                          brand story and are ready to become your biggest fans.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 love">
                    <div class="service-card" style={{}}>
                      <img
                        src="assets/img/service/caf.jpg"
                        class="service-imagemjgj"
                        style={{
                          border: "2px solid white",
                          width: "100%",
                          height: "330px",
                        }}
                        alt="Image not found"
                      />
                    </div>
                  </div>
                </div>
              </section>

              <section class="service-area" style={{ marginBottom: "30px" }}>
                <div class="row align-items-stretch">
                  <div class="col-lg-6" style={{ border: " 2px solid white" }}>
                    <div class="service-card">
                      <div class="service-card-content">
                        <h3 class="service-title">
                          {" "}
                          <span style={{ color: "#ffaa33" }}>
                            Full-Funnel
                          </span>{" "}
                          Approach
                        </h3>
                        <p class="service-description">
                          Gone are the leaky funnels and abandoned carts. We
                          design seamless customer journeys, guiding them from
                          "hello" to "checkout" with irresistible copy and
                          optimized landing pages.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 love">
                    <div class="service-card">
                      <img
                        src="assets/img/service/cy.jpg"
                        class="service-imagerdf"
                        style={{
                          border: "2px solid white",
                          width: "100%",
                          height: "330px",
                        }}
                        alt="Image not found"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <h1
              // class="sasup-s-title wow fadeInUp"
              data-wow-delay=".2s"
              className="here"
              style={{
                color: "white",
                marginTop: "60px",
                marginBottom: "60px",
                fontSize: "35px",
              }}
            >
              HERE ARE A FEW PARTNER
              <br></br>
              <span style={{ color: "#ffaa33" }}>CASE STUDIES</span>
            </h1>

            <div className="image-container wiw">
              <img
                src={tenten}
                alt="Partner Case Study"
                className="partner-image"
              />
              <img
                src={case2}
                alt="Partner Case Study"
                className="partner-image"
              />
              <img
                src={case3}
                alt="Partner Case Study"
                className="partner-image"
              />
              <img
                src={case4}
                alt="Partner Case Study"
                className="partner-image"
              />
            </div>
            <div
              style={{
                backgroundColor: "white !important",
                color: "black !important",
                margin: "auto",
                textAlign: "center",
                marginBottom: "60px",
              }}
            >
              <a
                href="https://calendly.com/adzenon-xta/free-30-minute-growth-map-scaling-session"
                style={{
                  backgroundColor: "white ",
                  color: "black ",
                  margin: "auto",
                  textAlign: "center",
                  padding: "15px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  border: "none",
                  borderRadius: "40px",
                }}
              >
                <b>Apply Now</b>
              </a>
            </div>
            <h1
              // class="sasup-s-title wow fadeInUp"
              data-wow-delay=".2s"
              className="what"
              style={{
                marginTop: "60px",
                marginBottom: "60px",
                fontSize: "35px",
              }}
            >
              WHAT OUR
              <br></br>
              <span style={{ color: "#ffaa33" }}>CLIENTS SAY</span>
            </h1>
            <section class="service-area  fix" style={{ marginBottom: "30px" }}>
              <div
                class="container"
                style={{ marginBottom: "30px", padding: "0 10px" }}
              >
                <div class="row justify-content-center align-items-stretch">
                  <div
                    class="col-lg-3 col-md-6 mb-4 xxx"
                    style={{
                      border: "2px solid white",
                      borderRadius: "20px",
                      backgroundColor: "#ffaa33",
                      height: "70% !important",
                    }}
                  >
                    <div class="">
                      <div class="">
                        <img
                          src={aa}
                          class="service-image"
                          style={{ width: "250px", height: "250px" }}
                          alt="Image not found"
                        />
                      </div>
                      <div class="sasup-service-list ">
                        <div class="sasup-single-list">
                          <div class="sasup-content">
                            <p style={{ color: "white" }}>
                              “Working with the team at Adzenon has been an
                              absolute pleasure. Each member brings unique
                              expertise to the table. From account managers to
                              creatives, everyone is dedicated to our success,
                              making us feel like valued partners every step of
                              the way.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-3 col-md-6 mb-4 xxx"
                    style={{
                      border: "2px solid white",
                      borderRadius: "20px",
                      backgroundColor: "#ffaa33",
                      height: "70% !important",
                    }}
                  >
                    <div>
                      <div>
                        <img
                          src={bb}
                          class="service-image"
                          alt="Image not found"
                        />
                      </div>
                      <div class="">
                        <div class="">
                          <div class="sasup-content">
                            <p style={{ color: "white" }}>
                              “Impressed by the efficiency of Adzenon's
                              processes! They've streamlined everything from
                              content creation to ad optimization, making our
                              campaigns not only effective but also
                              stress-free.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-3 col-md-6  mb-4 xxx"
                    style={{
                      border: "2px solid white",
                      borderRadius: "20px",
                      height: "70% !important",
                      backgroundColor: "#ffaa33",
                    }}
                  >
                    <div class="">
                      <div class="">
                        <img
                          src={cc}
                          class="service-image"
                          alt="Image not found"
                        />
                      </div>
                      <div class="sasup-service-list mb-25">
                        <div class="sasup-single-list">
                          <div class="sasup-content">
                            <p style={{ color: "white" }}>
                              “The content you never knew you wanted but knew
                              you always needed.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container" style={{ padding: "0 15px" }}>
                <div class="row justify-content-center align-items-stretch">
                  <div
                    class="col-lg-3 col-md-6 mb-4 xxx "
                    style={{
                      border: "2px solid white",
                      borderRadius: "20px",
                      backgroundColor: "#ffaa33",
                      height: "70% !important",
                    }}
                  >
                    <div class="service_left mb-60 mb-lg-0">
                      <div class="section-title mb-35">
                        <img
                          src={dd}
                          class="service-image"
                          alt="Image not found"
                        />
                      </div>
                      <div class="sasup-service-list mb-25">
                        <div class="sasup-single-list">
                          <div class="sasup-content">
                            <p style={{ color: "white" }}>
                              “Impressive system! Adzenon ensures campaigns are
                              meticulously planned and executed.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-3 col-md-6 mb-4 xxx"
                    style={{
                      border: "2px solid white",
                      borderRadius: "20px",
                      backgroundColor: "#ffaa33",
                      height: "70% !important",
                    }}
                  >
                    <div class="service_left mb-60 mb-lg-0">
                      <div class="section-title mb-35">
                        <img
                          src={ee}
                          class="service-image"
                          alt="Image not found"
                        />
                      </div>
                      <div class="sasup-service-list mb-25">
                        <div class="sasup-single-list">
                          <div class="sasup-content">
                            <p style={{ color: "white" }}>
                              "What they produced was way above what we asked
                              for."
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-3 col-md-6 mb-4 xxx"
                    style={{
                      border: "2px solid white",
                      borderRadius: "20px",
                      backgroundColor: "#ffaa33",
                      height: "70% !important",
                    }}
                  >
                    <div class="service_left mb-60 mb-lg-0">
                      <div class="section-title mb-35">
                        <img
                          src={ff}
                          class="service-image"
                          alt="Image not found"
                        />
                      </div>
                      <div class="sasup-service-list mb-25">
                        <div class="sasup-single-list">
                          <div class="sasup-content">
                            <p style={{ color: "white" }}>
                              “Their data-driven approach and paid social
                              strategies helped increase our conversions
                              significantly.”
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="image-container wiw">
              <img
                src={gant}
                alt="Partner Case Study"
                style={{ width: "80%", height: "80%", marginBottom: "50px" }}
              />
            </div>

            <div
              style={{
                backgroundColor: "white !important",
                color: "black !important",
                margin: "auto",
                textAlign: "center",
                marginBottom: "60px",
              }}
            >
              <a
                href="https://calendly.com/adzenon-xta/free-30-minute-growth-map-scaling-session"
                style={{
                  backgroundColor: "white ",
                  color: "black ",
                  margin: "auto",
                  textAlign: "center",
                  padding: "15px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  border: "none",
                  borderRadius: "40px",
                }}
              >
                <b>Apply Now</b>
              </a>
            </div>
            <section class="service-area fix" style={{ marginBottom: "30px" }}>
              <div class="container">
                <div class="row justify-content-center align-items-center">
                  <div
                    class="col-xxl-5 col-xl-5 col-lg-5"
                    style={{
                      border: "2px solid white",
                      borderRadius: "20px",
                      padding: "20px",
                      margin: "10px",
                    }}
                  >
                    <div class="service_left mb-60 mb-lg-0">
                      <div class="section-title mb-35">
                        <h3
                          class="sasup-s-title wow fadeInUp hidden-lg-br"
                          data-wow-delay=".2s"
                          style={{
                            color: "white",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                        >
                          We'll Help
                        </h3>
                      </div>
                      <div class="sasup-service-list mb-25">
                        <div class="sasup-single-list">
                          <div class="sasup-content">
                            <ul>
                              <li
                                style={{
                                  color: "white",
                                  listStyle: "none",
                                  position: "relative",
                                  marginBottom: "20px",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <label
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    style={{
                                      appearance: "none",
                                      width: "20px",
                                      height: "20px",
                                      border: "2px solid #ffaa33",
                                      borderRadius: "4px",
                                      marginRight: "10px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      fontSize: "28px",
                                      color: "#ffaa33",
                                    }}
                                  >
                                    ✔︎
                                  </span>
                                </label>

                                <span>
                                  DTC brands Crushing $50k+ <br></br>monthly.
                                </span>
                              </li>
                              <li
                                style={{
                                  color: "white",
                                  listStyle: "none",
                                  position: "relative",
                                  marginBottom: "20px",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <label
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    style={{
                                      appearance: "none",
                                      width: "20px",
                                      height: "20px",
                                      border: "2px solid #ffaa33",
                                      borderRadius: "4px",
                                      marginRight: "10px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      fontSize: "28px",
                                      color: "#ffaa33",
                                    }}
                                  >
                                    ✔︎
                                  </span>
                                </label>
                                <span>
                                  D2C brands spending or looking to invest $10k+
                                  monthly on Facebook, Google, or TikTok.
                                </span>
                              </li>
                              <li
                                style={{
                                  color: "white",
                                  listStyle: "none",
                                  position: "relative",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <label
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    style={{
                                      appearance: "none",
                                      width: "20px",
                                      height: "20px",
                                      border: "2px solid #ffaa33",
                                      borderRadius: "4px",
                                      marginRight: "10px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      fontSize: "28px",
                                      color: "#ffaa33",
                                    }}
                                  >
                                    ✔︎
                                  </span>
                                </label>
                                <span>
                                  D2C brands craving new, cutting-edge ways to
                                  skyrocket your brand's growth.
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-xxl-5 col-xl-5 col-lg-5"
                    style={{
                      border: "2px solid white",
                      borderRadius: "20px",
                      padding: "20px",
                      margin: "10px",
                    }}
                  >
                    <div class="service_left mb-60 mb-lg-0">
                      <div class="section-title mb-35">
                        <h3
                          class="sasup-s-title wow fadeInUp hidden-lg-br"
                          data-wow-delay=".2s"
                          style={{
                            color: "white",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                        >
                          We Won't Help
                        </h3>
                      </div>
                      <div class="sasup-service-list mb-25">
                        <div class="sasup-single-list">
                          <div class="sasup-content">
                            <ul>
                              <li
                                style={{
                                  color: "white",
                                  listStyle: "none",
                                  position: "relative",
                                  marginBottom: "20px",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <label
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    style={{
                                      appearance: "none",
                                      width: "20px",
                                      height: "20px",
                                      border: "2px solid #ffaa33",
                                      borderRadius: "4px",
                                      marginRight: "10px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      fontSize: "28px",
                                      color: "#ffaa33",
                                    }}
                                  >
                                    ✔︎
                                  </span>
                                </label>

                                <span>
                                  Business owners who want to micro manage our
                                  team.
                                </span>
                              </li>
                              <li
                                style={{
                                  color: "white",
                                  listStyle: "none",
                                  position: "relative",
                                  marginBottom: "20px",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <label
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    style={{
                                      appearance: "none",
                                      width: "20px",
                                      height: "20px",
                                      border: "2px solid #ffaa33",
                                      borderRadius: "4px",
                                      marginRight: "10px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      fontSize: "28px",
                                      color: "#ffaa33",
                                    }}
                                  >
                                    ✔︎
                                  </span>
                                </label>
                                <span>
                                  Non-invested brand owners looking for a quick
                                  fix to save their brand.
                                </span>
                              </li>
                              <li
                                style={{
                                  color: "white",
                                  listStyle: "none",
                                  position: "relative",
                                  display: "flex", // Add display flex to align items horizontally
                                  alignItems: "center", // Align items vertically to center
                                }}
                              >
                                <label
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    style={{
                                      appearance: "none",
                                      width: "20px",
                                      height: "20px",
                                      border: "2px solid #ffaa33",
                                      borderRadius: "4px",
                                      marginRight: "10px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      fontSize: "28px",
                                      color: "#ffaa33",
                                    }}
                                  >
                                    ✔︎
                                  </span>
                                </label>
                                <span>
                                  Although we love local we focus on online
                                  e-commerce domination.
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <p
                // class="sasup-s-title wow fadeInUp"
                data-wow-delay=".2s"
                className="what"
                style={{ marginBottom: "30px", marginTop: "30px" }}
              >
                FREQUENTLY ASKED QUESTIONS
                <br></br>
              </p>
              <Faq />
            </section>
            <div class="container max" style={{ marginTop: "50px" }}>
              <div
                style={{
                  backgroundImage: takeImage,
                  border: "10px solid white",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat", // Add this to prevent repeating the background image
                  padding: "20px",
                }}
              >
                <h6
                  class="sasup-s-title wow fadeInUp"
                  data-wow-delay=".2s"
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "30px",
                    textShadow:
                      "0 0 1px #000, 0 0 2px #000, 0 0 3px #000, 0 0 4px #000, 0 0 5px #000",
                  }}
                >
                  TAKE THE FIRST STEP. APPLY<br></br> TO SPEAK WITH OUR TEAM.
                </h6>
                <p
                  data-wow-delay=".2s"
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "18px",
                  }}
                >
                  Your future awaits - select your option below and let's make
                  magic together.
                </p>
                <div
                  style={{
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  <a
                    href="https://calendly.com/adzenon-xta/free-30-minute-growth-map-scaling-session"
                    style={{
                      backgroundColor: "black",
                      padding: "15px 40px",
                      borderRadius: "30px",
                      display: "inline-block",
                      color: "#fff", // Change button text color to ensure visibility
                      textDecoration: "none", // Remove default link underline
                      fontSize: "16px",
                    }}
                  >
                    <b>Apply Now</b>
                  </a>
                </div>
              </div>
            </div>
          </main>
        </body>
      </div>
    </>
  );
};

export default Land;
