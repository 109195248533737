import React, { useState } from "react";
import Faqq from "./Faqq";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./Faq.css";
function Faq() {
  const [faqs, setfaqs] = useState([
    {
      question: "	Do you offer a guarantee on your results?",
      answer:
        "We absolutely believe in the power of the CAF System to transform your e-commerce business. That's why we offer a 90-day profitability guarantee. If we don't help you reach profitability within 90 days of working together, we'll extend our services for an additional 2 months for FREE! We're that confident in our ability to drive real results.",
      open: false,
    },
    {
      question: "	What are your prices?",
      answer:
        "Our pricing is customized to your specific business needs. During our free consultation, we'll discuss your goals and challenges to recommend the most effective strategy for your budget. However, we stand behind our work with a performance-based risk-reversal guarantee. This means you only pay for results, and if we don't deliver, we'll work to make it right!",
      open: false,
    },

    {
      question: "	How will you be different than our current agency?",
      answer:
        "Unlike some, we focus on what matters: growth you can measure. Our 90-day profitability guarantee proves it. The CAF System delivers proven strategies (captivating content, laser targeting, frictionless funnels) that work. ",
      open: false,
    },

    {
      question: "Why don’t I just hire in-house instead?",
      answer:
        "Building a team takes time & resources. The CAF System offers instant expertise, seamlessly integrates with yours, and saves you money. Let's chat & unlock growth! ",
      open: false,
    },
    {
      question: "How does the CAF System work?",
      answer:
        "We focus on Creative content that stops scrollers dead in their tracks, laser-focused Audience targeting to reach your ideal customers, and a Frictionless sales funnel that converts clicks into buyers.",
      open: false,
    },
    {
      question: "What kind of results can I expect?",
      answer:
        "Our clients experience significant growth in sales and brand awareness. We'll share real success stories during your free consultation!",
      open: false,
    },
    {
      question: "Is there a free trial?",
      answer:
        " Not exactly, but even better! We offer a free 30-minute consultation to assess your business and create a personalized growth plan using the CAF System and our 90-day results guarantee eliminates the risk.",
      open: false,
    },
    {
      question: "How do I get started? ",
      answer:
        "Simply scroll down and click the button to book your FREE consultation. Let's unlock your e-commerce potential and achieve profitability faster with our risk-free guarantee!",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="App">
      <div className="faqs">
        {faqs.map((faq, i) => (
          <div key={i} className="faq-item">
            <Faqq faq={faq} index={i} toggleFAQ={toggleFAQ}></Faqq>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
