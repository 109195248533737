// ParentTable.js
import React, { useState } from "react";
import tenten from "./19.png";
const Nav = () => {
  return (
    <>
      <div>
        <div>
          <img src="assets/img/service/case.png" alt="Partner Case Study" />
          <img src="assets/img/service/ss.png" alt="Partner Case Study" />
        </div>
      </div>
    </>
  );
};

export default Nav;
